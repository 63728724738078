import React from "react";
import { Watch } from "react-loader-spinner";
import Tooltip from '@material-ui/core/Tooltip';

function SpinnerComponent() {
  return (
    <>
      <div className="spinner-div center">
        <div className="card">
          <div className="columns is-centered">
            <div className="column">
              <br />
              <br />
              <br />
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column column is-3 is-mobile"></div>
            <div className="column column is-6 is-mobile">
              <center>
              <Tooltip title="Loading ...">
                <Watch
                  visible={true}
                  ariaLabel="Watch-loading"
                  color="#F26E50"
                  height={200}
                  width={400} 
                  //wrapperStyle={ width: 10vw,}
                />
                </Tooltip>
                <h3 >Loading...</h3>
              </center>
            </div>

            <div className="column column is-3 is-mobile"> </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SpinnerComponent;
