import React, { Suspense } from "react";
import Container from './Components/Container';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop';
// eslint-disable-next-line no-unused-vars
import styles from './Static/scss/styles.scss';
import Provider from 'react-use-dark-mode';
import { ParallaxProvider } from 'react-scroll-parallax';
import SpinnerComponent from "./Components/SpinnerComponent";

//Google Analytics
import ReactGA from "react-ga4";
ReactGA.initialize('G-LXB4ZPDH8D');
ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

function App() {
  return (
    <Suspense fallback={<SpinnerComponent />}>
    <ParallaxProvider>
    <Router>
      <Provider>
        <ScrollToTop>
          <Container />
        </ScrollToTop>
      </Provider>
    </Router>
    </ParallaxProvider>
    </Suspense>
  );
}
export default App;
