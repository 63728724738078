/* eslint-disable */
import React from "react";
/*import { Helmet } from "react-helmet";*/
import { Helmet } from "react-helmet";
import { Switch, Route, withRouter } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

const HomePage = React.lazy(() => import("../Home/HomePage"));
const CvPage = React.lazy(() => import("../CV_Pagev2/CVPageV2"));

const title = "Hello, I am Alvaro";
const description = "Alvaro Sanchez - Technology Consultant";
const type = "Professional Services";
const name = "Alvaro Sanchez";

function Container({ location }) {
 
  return (
    <>
      <Helmet>
        {/* Standard metadata tags */}

        {/* title attributes and value */}
        <title>{title}</title>
        <html lang="en" amp />
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="author" content={name} />
        {/* html attributes */}
        <html lang="en" amp />

      
        <link rel="canonical" href="https://alvarosanchez.digital/" />
        {/* End standard metadata tags */}
        {/* Facebook tags */}
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="https://alvarosanchez.digital/" />
        {/* End Facebook tags */}
        {/* Twitter tags */}
        <meta name="twitter:creator" content={name} />
        <meta name="twitter:card" content={type} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {/* End Twitter tags */}

        {/* Others */}

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#FFFFFF" />

        <meta
          name="keywords"
          content="java, coding, technology, react, alvaro, head of software engineering, consultant, sanchez, alvaro sanchez"
        />
        <meta name="robots" content="index, follow" />
        {/* End of Others */}
      </Helmet>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch location={location}>
        <Route exact path="/cv" component={CvPage} />
        <Route exact path="/" component={HomePage} />
      </Switch>

      <CookieConsent
        location="top"
        buttonText="ACCEPT"
        cookieName="cookie_consent"
        style={{ background: "#023059" }}
        buttonStyle={{ color: "#023059", fontSize: "16px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience
      </CookieConsent>
    </>
  );
}

export default withRouter(Container);
